<template>
  <div class="center">
    <div class="list">
      <div :key="item.serviceOrderId" class="table" v-for="item in tableDate">
        <div class="top">
          <div style="color: #333333">
            下单人：{{ item.consumerName }} &nbsp;&nbsp;
            {{ item.consumerTelephone }}
          </div>
          <div>
            <i class="hy-icon-shijian1"></i> {{ item.createTime }}
            <span class="status">{{ applyStatus[item.applyStatus] }}</span>
          </div>
        </div>
        <div class="bottom">
          <div class="left pointer" @click="todetail(item.serviceOrderId)">
            <div class="img">
              <el-image
                style="width: 100%; height: 100%"
                :src="item.mainGraph"
              />
            </div>
            <div class="text">
              <div class="first f-hide" style="width: 400px">
                {{ item.name }}
              </div>
              <div class="two" v-show="item.applyStatus == 1">
                <span>{{
                  "授信额度：￥" +
                  (item.creditLine || 0) +
                  " |  可用额度：￥" +
                  (item.availableQuota || 0) +
                  " |  已借金额：￥" +
                  (item.borrowedAmount || 0) +
                  " |  待还金额：￥" +
                  (item.repaymentAmount || 0)
                }}</span>
              </div>
            </div>
          </div>
          <div class="rights">
            <el-button
              style="width: 100px"
              v-show="item.applyStatus !== 1"
              type="primary"
              plain
              size="mini"
              @click="todetail(item.serviceOrderId)"
              >查看详情</el-button
            >
            <el-button
              style="width: 100px"
              class="button"
              v-show="item.applyStatus == 1"
              type="primary"
              size="mini"
              @click="toborrow(item)"
              >去借款</el-button
            ><el-button
              style="margin-top: 3px; width: 100px"
              v-show="item.applyStatus == 1"
              type="primary"
              plain
              size="mini"
              @click="todelborrow(item.serviceOrderId)"
              >去还款</el-button
            >
            <!-- <el-button
              style="margin-top: 3px; width: 100px"
              v-show="item.applyStatus == 1"
              type="primary"
              plain
              size="mini"
              @click="missorder(item.serviceOrderId)"
              >取消订单</el-button
            > -->
          </div>
        </div>
      </div>
    </div>
    <div class="allpage">
      <el-pagination
        background
        :total="total"
        :page-size="formInline.pageSize"
        :current-page="formInline.pageNum"
        @current-change="handleCurrentChange"
        layout="prev, pager, next"
      >
      </el-pagination>
    </div>
    <el-dialog
      class="zyq_dialog"
      title="借款申请"
      :visible.sync="dialogVisible"
      width="1217px"
      :before-close="handleClose"
    >
      <div class="dialog_center">
        <el-form
          :model="form"
          :rules="rules"
          ref="dynamicValidateForm"
          label-width="160px"
          class="demo-dynamic"
        >
          <el-col :span="8">
            <el-form-item label="授信编号：" prop="applyCode">
              <el-input
                size="small"
                disabled
                placeholder="请输入授信编号"
                v-model="form.applyCode"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="产品名称：" prop="productName">
              <el-input
                size="small"
                disabled
                placeholder="请输入产品名称"
                v-model="form.productName"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="最高可借款金额(元)：" prop="availableQuota">
              <el-input
                size="small"
                disabled
                placeholder="请输入最高可借款金额"
                v-model="form.availableQuota"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="申请日期：" prop="applyDate">
              <el-input
                size="small"
                disabled
                placeholder="请输入申请日期"
                v-model="form.applyDate"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="贷款金额(元)：" prop="loanAmount">
              <el-input
                size="small"
                oninput="value=value.replace(/[^\d^\.]/g,'')"
                maxlength="20"
                placeholder="请输入贷款金额"
                v-model="form.loanAmount"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="还款方式：" prop="repaymentType">
              <el-select
                disabled
                size="small"
                v-model="form.repaymentType"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="贷款期限单位：" prop="loanTermUnit">
              <el-select
                size="small"
                @change="UnitChange"
                v-model="form.loanTermUnit"
                placeholder="请选择贷款期限单位"
              >
                <el-option
                  v-for="item in options1"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="ruValue" prop="loanTerm">
              <el-input
                size="small"
                oninput="value=value.replace(/[^\d^\.]/g,'')"
                maxlength="20"
                placeholder="请输入贷款期限"
                v-model="form.loanTerm"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="参考月利率：" prop="monthInterestRate">
              <el-input
                size="small"
                placeholder=""
                v-model="form.monthInterestRate"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="资金用途：" prop="fundsPurpose">
              <el-select
                size="small"
                v-model="form.fundsPurpose"
                placeholder="请选择资金用途"
              >
                <el-option
                  v-for="item in optione"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="放款帐号：" prop="loanBankAccount">
              <!-- <el-input
                size="small"
                oninput="value=value.replace(/[^\d^\.]/g,'')"
                placeholder="请输入放款账号"
                maxlength="20"
                v-model="form.loanBankAccount"
              ></el-input> -->

              <el-autocomplete
                class="inline-input"
                 size="small"
                v-model="form.loanBankAccount"
                :fetch-suggestions="querySearch"
                value-key="accNo"
                placeholder="请输入内容"
                label='accNo'
                @select="handleSelect"
              ></el-autocomplete>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="放款账户名称：" prop="loanAccountName">
              <el-input
                size="small"
                maxlength="20"
                placeholder="请输入放款账户名称"
                v-model="form.loanAccountName"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="放款账户开户行：" prop="loanBankName">
              <el-input
                size="small"
                v-model="form.loanBankName"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleSure">提交申请</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  listServiceOrder,
  borrowFundsRecord,
  addborrowFundsRecord,
  listBankAccount,
  getqxshuju,
} from "@/api/home";
const { baseUrl } = require("@/config/index.js");
import * as types from "@/store/action-types";
import Vue from "vue";
export default {
  data() {
    var valiregisterTime = (rule, value, callback) => {
      if (this.form.loanAmount) {
        let num = Number(this.form.loanAmount);
        let num2 = Number(this.form.availableQuota);
        if (num2 < num) {
          callback(new Error("贷款金额不能大于可贷款金额"));
        } else {
          callback();
        }
      }
    };
    return {
      openUrl: null,
      rules: {
        loanAmount: [
          { required: true, message: "请输入贷款金额", trigger: "change" },
          { validator: valiregisterTime, trigger: "change" },
        ],
        loanAccountName: [
          { required: true, message: "请输入放款账户名称", trigger: "blur" },
        ],
        loanTerm: [
          { required: true, message: "请输入贷款期限", trigger: "blur" },
        ],
        fundsPurpose: [
          { required: true, message: "请选择资金用途", trigger: "blur" },
        ],
        loanBankAccount: [
          { required: true, message: "请输入放款账号", trigger: "blur" },
        ],
      },
      form: {
        repaymentType: 0,
        loanBankName: "哈密天山村镇银行营业部",
        loanTermUnit: 0,
        loanTerm: "",
        fundsPurpose: null,
        loanBankAccount: "",
        loanAccountName: "",
        loanAmount: "",
      },
      options1: [
        {
          value: 0,
          label: "天",
        },
        {
          value: 1,
          label: "月",
        },
        {
          value: 2,
          label: "年",
        },
      ],
      options: [
        {
          value: 0,
          label: "利随本清",
        },
        {
          value: 5,
          label: "初期付息，到期还本",
        },
      ],
      optione: [
        {
          value: 0,
          label: "支付货款",
        },
        {
          value: 1,
          label: "支付工资",
        },
        {
          value: 2,
          label: "其他",
        },
        {
          value: 3,
          label: "经营周转",
        },
      ],
      applyStatus: ["待处理", "已通过", "已拒绝"],
      tableDate: [],
      total: 0,

      dialogVisible: false,
      formInline: {
        consumerId: "",
        dictId: "505",
        pageSize: 4,
        pageNum: 1,
        orderType: 2,
      },
      ruValue: "贷款期限(天)",
      restaurants:[]
    };
  },
  mounted() {
    this.formInline.consumerId = Vue.ls.get(types.enterpriseId);
    this.getList();
  },
  watch: {
    openUrl() {
      if (this.openUrl) {
        window.open(this.openUrl, "_blank");
        this.openUrl = null;
      }
    },
  },
  methods: {
    querySearch(queryString, cb) {
      console.log(queryString)
      var restaurants = this.restaurants;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
        return (restaurant) => {
          console.log(restaurant,'restaurantrestaurant')
          return (restaurant.accNo.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
        };
      },
    handleSelect(item) {
      console.log(item);
      this.form.loanBankAccount=item.accNo
      this.$refs.dynamicValidateForm.validateField('loanBankAccount', ErrorMsg => {})
      this.form.loanAccountName=item.accNm
      this.form.loanBankName=item.accBlngBnk
    },
    UnitChange(e) {
      if (e == 0) {
        this.ruValue = "贷款期限(天)";
      }
      if (e == 1) {
        this.ruValue = "贷款期限(月)";
      }
      if (e == 2) {
        this.ruValue = "贷款期限(年)";
      }
    },
    missorder() {
      this.$confirm("请确认要取消订单吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        customClass: "zzysd_confirm",
      })
        .then(() => {
          // this.$message({
          //   type: 'success',
          //   message: '删除成功!'
          // });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    todelborrow(id) {
      if (!this.beforhandle("personalCenter:pfinancialOrder:back")) return;
      this.$confirm(
        "请保证您在天山村镇银行开设的还款账户余额充足，到期后会自动扣款。",
        "提示",
        {
          confirmButtonText: "已知悉",
          cancelButtonText: "取消",
          type: "warning",
          customClass: "zzysd_confirm",
        }
      )
        .then(() => {
          // this.$message({
          //   type: 'success',
          //   message: '删除成功!'
          // });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    toborrow(item) {
      if (!this.beforhandle("personalCenter:pfinancialOrder:borrow")) return;
      console.log(item);
      getqxshuju().then((res) => {
        if (res.code == 0) {
          listBankAccount(res.data.socialCode).then((res) => {
            console.log(res, "res");
            this.restaurants=res.data.accList
          });
        }
      });
      // listBankAccount
      borrowFundsRecord({ serviceOrderId: item.serviceOrderId }).then((res) => {
        if (res.code == 0) {
          this.form.applyCode = res.data.applyCode;
          this.form.productName = res.data.productName;
          this.form.availableQuota = res.data.availableQuota;
          this.form.applyDate = res.data.applyDate
            ? res.data.applyDate.replace("T", " ")
            : "";
          this.form.monthInterestRate = res.data.monthInterestRate;
          if (res.data.signUrl) {
            window.open(res.data.signUrl);
          } else if (res.data.url) {
            window.open(res.data.url);
          } else {
            this.dialogVisible = true;
          }
        } else {
          this.$message.error(res.message);
        }
      });
    },
    handleClose() {
      this.$refs.dynamicValidateForm.resetFields();
      this.dialogVisible = false;
    },
    handleSure() {
      this.$refs.dynamicValidateForm.validate((v) => {
        if (v) {
          addborrowFundsRecord(this.form).then((res) => {
            if (res.code == 0) {
              this.handleClose();
              this.openUrl = res.data;
              this.$message.success("申请成功");
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    async getList() {
      console.log(
        this.formInline,
        "this.formInlinethis.formInlinethis.formInline"
      );
      const res = await listServiceOrder(this.formInline);
      if (res.code == 0) {
        this.tableDate = res.data.list.map((item) => {
          item.serviceOrderVO.createTime =
            item.serviceOrderVO.createTime.replace("T", " ");
          return {
            ...item.serviceOrderVO,
            mainGraph:
              baseUrl +
              "third/third/ossGetFile/" +
              item.serviceOrderVO.mainGraph,
            ...item.finaceInfo,
          };
        });
        console.log(this.tableDate);
        this.total = res.data.total;
      }
    },
    todetail(id) {
      if (!this.beforhandle("personalCenter:pfinancialOrder:detail")) return;
      this.$router.push({
        path: "/usbankingdetail",
        query: {
          id,
        },
      });
    },
    handleCurrentChange(value) {
      this.formInline.pageNum = value;
      console.log(this.formInline);
      this.getList();
    },
  },
};
</script>
<style lang="scss">
.zzysd_confirm {
  .el-message-box {
    width: 515px !important;
    height: 243px !important;
    background: #ffffff !important;
    border-radius: 10px !important;
  }
  .el-message-box__header {
    background: #f8f8f8;
  }
  .el-message-box__title {
    font-size: 16px;
    font-weight: 400;
    color: #666666;
  }
  .el-message-box__content {
    padding: 49px 105px;
    color: #606266;
    font-size: 14px;
    height: 147px;
  }
  .el-message-box__btns {
    .el-button--primary {
      background: linear-gradient(90deg, #2079fb, #4cb2fc);
    }
  }
}

.zyq_dialog {
  .el-dialog {
    border-radius: 10px;
  }
  .el-dialog__header {
    height: 53px;
    padding: 19px 25px;
    background: #f8f8f8;
    border-radius: 10px;
    .el-dialog__title {
      font-size: 16px;
      line-height: 16px;
      color: #666666;
    }
  }
  .el-dialog__footer {
    padding: 18px 25px 25px;
    .el-button--primary {
      background: linear-gradient(90deg, #2079fb, #4cb2fc);
    }
  }
  // input[type=text] {
  //   height: 34px;
  // }
  .demo-dynamic {
    width: 100%;
  }
  .el-input {
    width: 211px;
  }
  .dialog_center {
    display: flex;
    justify-content: center;
  }
}
</style>
<style lang="scss" scoped>
.center {
  width: 1195px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-shadow: -1px 0px 10px 0px rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
  .search {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    border-bottom: 1px solid #e5e5e5;
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: 800;
    .action {
      width: 33px;
      height: 3px;
      margin-top: 3px;
      background: linear-gradient(90deg, #2079fb, #4cb2fc);
      border-radius: 2px;
    }
    .notaction {
      width: 33px;
      height: 3px;
      margin-top: 3px;
      border-radius: 2px;
    }
    .ing {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 50%;
      height: 100%;
    }
    .ed {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 50%;
      height: 100%;
      border-left: 1px solid #e5e5e5;
    }
  }
  .list {
    padding: 16px;
    padding-bottom: 0px;
    .table {
      height: 162px;
      border: 1px solid #e5e5e5;
      border-radius: 4px;
      margin-bottom: 16px;
      .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 43px;
        padding: 0 16px;
        border-bottom: 1px dashed #e5e5e5;
        color: #999999;
        .status {
          padding-left: 41px;
          color: #0a9d41;
        }
      }
      .bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 118px;
        padding: 0 8px;
      }
      .left {
        display: flex;
        .img {
          width: 167px;
          height: 97px;
          border-radius: 2px;
          overflow: hidden;
        }
        .text {
          margin-left: 17px;
          .first {
            height: 20px;
            line-height: 20px;
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: 500;
            margin-top: 4px;
            margin-bottom: 20px;
            font-size: 20px;
            color: #40474e;
          }
          .two {
            height: 14px;
            line-height: 14px;
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #999999;
            margin-bottom: 4px;
          }
        }
      }
      .middle {
        .one {
          font-size: 12px;
          color: #6d6b6b;
        }
        .two {
          font-size: 16px;
          color: #d0111a;
        }
        .three {
          padding-left: 4px;
          font-size: 28px;
          font-weight: 600;
          color: #d0111a;
        }
      }
      .right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        height: 80px;
        padding-right: 17px;
        .status {
          padding: 4px 13px;
          height: 19px;
          line-height: 19px;
          font-size: 12px;
          color: #fff;
          background: #f87b28;
          border-radius: 10px;
        }
        .do {
          width: 127px;
          height: 29px;
          line-height: 29px;
          text-align: center;
          background: #ffffff;
          border: 1px solid #b3d8ff;
          border-radius: 4px;
        }
      }
      .rights {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        height: 80px;
        padding-right: 17px;
        .status {
          padding: 4px 13px;
          height: 19px;
          line-height: 19px;
          font-size: 12px;
          color: #fff;
          background: #f87b28;
          border-radius: 10px;
        }
        .do {
          width: 127px;
          height: 29px;
          line-height: 29px;
          text-align: center;
          background: #ffffff;
          border: 1px solid #b3d8ff;
          border-radius: 4px;
        }
      }
    }
  }
  .allpage {
    text-align: right;
    margin-bottom: 20px;
    margin-right: 6px;
  }
  .button {
    background: linear-gradient(90deg, #2079fb, #4cb2fc);
  }
}
</style>>